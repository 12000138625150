<template>
    <div class="vimeo-wrapper h-100 w-100"
         ref="cover">
        <vue-vimeo-player ref="vimeo"
                          class="vimeo-player"
                          v-bind:style="coverStyle"
                          v-bind:video-id="videoId"
                          v-bind:autoplay="true"
                          v-bind:loop="true"
                          v-bind:options="vimeoPlayerVariables"
                          v-on:loaded="vimeoVideoReady"
                          v-on:play="vimeoVideoPlaying">
        </vue-vimeo-player>
    </div>
</template>

<script>
import { defineComponent, } from "vue";

import { vueVimeoPlayer } from "vue-vimeo-player";

export default defineComponent({
    name: "VimeoCover",
    components: {
        vueVimeoPlayer
    },
    props: {
        videoId: {
            type: String,
            required: true,
        }
    },
    data () {
        return {
            windowWidth: window.innerWidth,

            vimeoPlayerVariables: {
                title: false,
                byline: false,
                portrait: false
            },

            videoHeight: null,
            videoWidth: null,
        }
    },
    computed: {
        coverStyle () {
            if (
                !this.videoHeight ||
                !this.videoWidth
            ) {
                return {
                    width: "100%",
                    height: "100%"
                };
            }

            let elementSize = this.$refs.cover.getBoundingClientRect();

            let elementHeightToVideoHeightRatio = elementSize.height / this.videoHeight;
            let coverWidth = elementHeightToVideoHeightRatio * this.videoWidth;
            let coverHeight = elementHeightToVideoHeightRatio * this.videoHeight;

            if (coverWidth < elementSize.width) {
                let elementWidthToVideoWidthRatio = elementSize.width / this.videoWidth;
                coverWidth = elementWidthToVideoWidthRatio * this.videoWidth;
                coverHeight = elementWidthToVideoWidthRatio * this.videoHeight;
            }

            return {
                width: `${ coverWidth }px`,
                height: `${ coverHeight }px`
            };
        },
    },
    methods: {
        vimeoVideoReady () {
            this.$emit("ready");
        },

        vimeoVideoPlaying () {
            this.$emit("play");
        }
    },
    async mounted () {
        if (this.videoId) {
            let response = await fetch(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${ this.videoId }`, {
                method: "GET",
                mode: "cors",
            });

            if (response.ok) {
                let videoInfo = await response.json();
                this.videoHeight = videoInfo.height;
                this.videoWidth = videoInfo.width;
            }
        }

        window.addEventListener(
            "resize",
            async () => {
                if (this.videoId) {
                    let response = await fetch(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${ this.videoId }`, {
                        method: "GET",
                        mode: "cors",
                    });

                    if (response.ok) {
                        let videoInfo = await response.json();
                        this.videoHeight = videoInfo.height;
                        this.videoWidth = videoInfo.width;
                    }
                }
            }
        );
    },
    watch: {
        async videoId (newVal) {
            if (!newVal) {
                return;
            }
            const response = await fetch(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${ newVal }`, {
                method: "GET",
                mode: "cors",
            });

            if (response.ok) {
                let videoInfo = await response.json();
                this.videoHeight = videoInfo.height;
                this.videoWidth = videoInfo.width;
            }
        }
    }
});
</script>

<style lang="scss" scoped>
.vimeo-player {
    width: 100%;
    height: calc(100vw / 16 * 9);
}
</style>

<style lang="scss">
.vimeo-player {
    iframe {
        width: 100%;
        height: 100%;
    }
}
</style>
