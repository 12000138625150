import localforage from "localforage";
import { apiUrl, apiVersion, } from "../../config.js";

export async function addCard (sourceId) {
    const token = await localforage.getItem("marier.user-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/wallet/source/${ sourceId }`,
        {
            method: "PUT",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${ token }`
            },
        }
    );

    if (response.ok) {
        const wallet = await response.json();
        if (wallet) {
            return wallet;
        }
        throw "WALLET ADD CARD error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default addCard;
