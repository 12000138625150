import Vue from "vue";
import Router from "vue-router";
import stores from "../stores/index.js";

import Products from "../views/Products.vue";
import ProductDetails from "../views/ProductDetails.vue";
import ProductSeries from "../views/ProductSeries.vue";

import Treatments from "../views/Treatments.vue";
import TreatmentDetails from "../views/TreatmentDetails.vue";
import TreatmentFAQ from "../views/TreatmentFAQ.vue";

Vue.use(Router);

const routes = [
    {
        path: "/",
        component: () => import("../layouts/Default.vue"),
        children: [
            {
                path: "/",
                name: "home",
                component: () => import("../views/Home.vue")
            },
            {
                path: "/account",
                name: "account",
                component: () => import("../views/Account.vue"),
                children: [
                    {
                        path: "",
                        name: "account-info",
                        component: () => import("../components/Account/AccountDashboard.vue"),
                    },
                    {
                        path: "orders",
                        name: "account-orders",
                        component: () => import("../components/Account/AccountOrders.vue"),
                    },
                    {
                        path: "bookings",
                        name: "account-bookings",
                        component: () => import("../components/Account/AccountBookings.vue"),
                    }
                ]
            },
            {
                path: "/forget-password",
                name: "forget-password",
                component: () => import("../views/ForgetPassword.vue")
            },
            {
                path: "/about",
                name: "about",
                component: () => import("../views/About.vue")
            },
            {
                path: "/contact",
                name: "contact",
                component: () => import("../views/Contact.vue")
            },
            {
                path: "/campaign/emsculpt",
                redirect: "/campaign/emsculptV2"
            },
            {
                path: "/campaign/ultra-femme-360-v2",
                redirect: "/campaign/intima"
            },
            {
                path: "/campaign",
                name: "campaign",
                component: () => import("../views/Campaign.vue"),
                children: [
                    {
                        path: "ultra-femme-360",
                        redirect: "/campaign/ultra-femme-360-v2"
                    },
                    {
                        path: ":id",
                        name: "campaign-index",
                        component: () => import("../components/campaign/CampaignIndex.vue"),
                        props: true,
                    },
                ]
            },
            {
                path: "/locations",
                name: "locations",
                component: () => import("../views/Locations.vue")
            },
            {
                path: "/privacy",
                name: "privacy",
                component: () => import("../views/Privacy.vue")
            },
            {
                path: "/terms",
                name: "terms",
                component: () => import("../views/Terms.vue")
            },
            {
                path: "/payment-methods",
                name: "payment-methods",
                component: () => import("../views/PaymentMethods.vue")
            },
            {
                path: "/online-shopping-terms",
                name: "online-shopping-terms",
                component: () => import("../views/OnlineShoppingTerms.vue")
            },
            {
                path: "/delivery-methods",
                name: "delivery-methods",
                component: () => import("../views/DeliveryMethods.vue")
            },
            {
                path: "/login",
                name: "login",
                component: () => import("../views/Login.vue")
            },
            {
                path: "/register",
                name: "register",
                component: () => import("../views/Register.vue"),
            },
            {
                path: "/basket",
                name: "basket",
                component: () => import("../views/Basket.vue"),
            },
            {
                path: "/checkout/:type",
                name: "checkout",
                component: () => import("../views/Checkout.vue"),
                props: true,
                beforeEnter (to, from, next) {
                    if (stores.getters["user/status"] !== "loggedIn") {
                        const route = {
                            "name": "login",
                            "query": {
                                "to": to.path,
                            }
                        };
                        next(route);
                        return;
                    }
                    next();
                },
                children: [
                    {
                        path: "",
                        redirect: "delivery",
                    },
                    {
                        path: "delivery",
                        name: "checkout-delivery",
                        component: () => import("../components/Checkout/Delivery.vue"),
                        props: true,
                    },
                    {
                        path: "order",
                        name: "checkout-order-details",
                        component: () => import("../components/Checkout/OrderDetails.vue"),
                        props: true,
                    },
                    {
                        path: "payment/:orderId",
                        name: "checkout-payment",
                        component: () => import("../components/Checkout/Payment.vue"),
                        props: true,
                    },
                    {
                        path: "complete/:orderId",
                        name: "checkout-completion",
                        component: () => import("../components/Checkout/Completion.vue"),
                        props: true,
                    },
                ]
            },
            /*{
                path: "/testimonials",
                name: "testimonials",
                component: () => import("./views/Testimonials.vue")
            },
            {
                path: "/testimonials/:id",
                name: "testimonial-details",
                component: () => import("./views/TestimonialDetails.vue"),
                props: true,
            },
            */
            {
                path: "/blog",
                name: "blog",
                component: () => import("../views/Blog.vue")
            },
            {
                path: "/blog/:id",
                name: "blog-details",
                component: () => import("../views/BlogDetails.vue"),
                props: true,
            },
            {
                path: "/news",
                name: "news",
                component: () => import("../views/News.vue")
            },
            {
                path: "/news/:id",
                name: "news-details",
                component: () => import("../views/NewsDetails.vue"),
                props: true,
            },
            {
                path: "/products",
                name: "products",
                component: Products,
            },
            {
                path: "/products/:id",
                name: "product-details",
                component: ProductDetails,
                props: true
            },
            {
                path: "/series/:id",
                name: "product-series",
                component: ProductSeries,
                props: true
            },
            {
                path: "/treatments",
                name: "treatments",
                component: Treatments
            },
            {
                path: "/treatment-faq",
                name: "treatment-faq",
                component: TreatmentFAQ
            },
            {
                path: "/treatments/:id",
                name: "treatment-details",
                component: TreatmentDetails,
                props: true
            },
            {
                path: "/tc/campaigns/1",
                redirect: "/campaign/ultra-face-360"
            },
            {
                path: "/tc/campaigns/2",
                redirect: "/campaign/silkpeel"
            },
            {
                path: "/tc/campaigns/3",
                redirect: "/campaign/intima"
            },
            {
                path: "*",
                redirect: {
                    name: "home"
                }
            }
        ]
    },
]

const router = new Router(
    {
        mode: "history",
        routes,
        scrollBehavior (to, from, savedPosition) {
            if (
                [
                    "checkout-payment-cards",
                    "checkout-payment-paypal",
                    "checkout-payment-alipay",
                    "checkout-payment-wechat-pay",
                ].includes(to.name)
            ) {
                if (from.name === "checkout-delivery") {
                    return { x: 0, y: 0 };
                }
                return savedPosition;
            }

            if (to.hash) {
                console.log("test", to.hash);
                return {
                    selector: to.hash,
                    offset: { "x": 0, "y": 65 }
                }
            }

            return { "x": 0, "y": 0 };
        }
    }
);

router.beforeEach(
    (to, from, next) => {
        next();
    }
);

export default router;
