import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./stores/index.js";

Vue.config.productionTip = false;

//import "u2f-api-polyfill";

import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

import "intersection-observer";

import Autocomplete from "v-autocomplete";
import "v-autocomplete/dist/v-autocomplete.css";
Vue.use(Autocomplete);

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    Vue,
    dsn: "https://b06901e6eeab4d2fa94698ce320570e2@o474560.ingest.sentry.io/5626061",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

import vue2GoogleMap from "./init/vue2-google-maps.js";
vue2GoogleMap(Vue);

import VueI18n from "./init/vue-i18n.js";
const i18n = VueI18n(Vue);

import "./init/font-awesome";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

import lineClamp from "vue-line-clamp";
Vue.use(lineClamp, {});

import VueChartist from "vue-chartist";
Vue.use(VueChartist);

//import VueYoutube from "vue-youtube";
//Vue.use(VueYoutube);

import VueProgressiveImage from "vue-progressive-image";
Vue.use(VueProgressiveImage);

import { capitalize, currency } from "./plugins/filters/index.js";
Vue.use(capitalize);
Vue.use(currency);

import vueDateFnsFormatter from "./plugins/date-fns-formatter/index.js";
Vue.use(vueDateFnsFormatter);

import { FacebookAppId } from "./config.js";
import VueMultianalytics from "vue-multianalytics";
Vue.use(
    VueMultianalytics,
    {
        "modules": {
            "facebook": {
                token: FacebookAppId,
                debug: true,
            }
        },
        "routing": {
            vueRouter: router,
            preferredProperty: "path",
            ignoredViews: [],
            ignoredModules: []
        }
    }
);

import VueGtag from "vue-gtag";
Vue.use(
    VueGtag,
    {
        config: {
            id: "G-SQ4F6DJ8S1",
            params: {
                send_page_view: true,
            },
        },
        includes: [
            {
                id: "GTM-5V6BC7Q",
                params: {
                    send_page_view: true,
                },
            },
            {
                id: "UA-62227809-5",
                params: {
                    send_page_view: true,
                },
            },
            {
                id: "GTM-57MCJX4",
                params: {
                    send_page_view: true,
                },
            },
        ],
    },
    router
);

import { baseUrl, apiUrl, apiVersion, SupportedSNS, } from "./config.js";
import VueConfigurator from "./plugins/configurator/index.js";
Vue.use(
    VueConfigurator,
    { baseUrl, apiUrl, apiVersion, SupportedSNS, }
);

//import VueNProgress from "./plugins/nprogress/index";
import "nprogress/nprogress.css";
//Vue.use(VueNProgress);

import VueDetectBrowser from "./plugins/detect-browser/index.js";
Vue.use(VueDetectBrowser);

import VueSanitizeHtml from "./plugins/sanitize-html/index.js";
Vue.use(VueSanitizeHtml);

import VueStickyEvent from "./plugins/directives/sticky-event.js";
Vue.use(VueStickyEvent);

import { stripeKey } from "./config.js";
import StripeInit from "./plugins/stripe/index.js";
Vue.use(StripeInit, stripeKey);

import Meta from "vue-meta";
Vue.use(Meta);

import "aos/dist/aos.css";

import "./registerServiceWorker.js";

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");
