import { apiUrl, apiVersion, } from "../../config.js";

function hexString(buffer) {
    const byteArray = new Uint8Array(buffer);
    const hexCodes = [...byteArray].map(
        value => {
            const hexCode = value.toString(16);
            return hexCode.padStart(2, "0");
        }
    );
    return hexCodes.join("");
}

function digestMessage(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    return window.crypto.subtle.digest("SHA-512", data);
}

export async function register (
    { title, surname, name, countryCode, phone, email, password, profile, }
) {
    const hashed = await digestMessage(password);

    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/user/register`,
        {
        method: "POST",
        mode: 'cors',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(
            {
                "mode": "email",
                title,
                surname,
                name,
                countryCode,
                phone,
                email,
                "password": hexString(hashed),
                "twoFactorAuth": false,
                profile
            }
        ),
    }
    );

    if (response.ok) {
        const user = await response.json();
        if (user) {
            return user;
        }
        throw "Register error";
    }

    if (response.status === 400) {
        throw await response.json();
    }

    throw "Internal server error";
}

export default register;
