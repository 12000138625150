import { apiUrl, apiVersion, } from "../../config.js";

export async function loginByOAuth (
    { vendor, token, lang, }
) {
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/user/login`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(
                {
                    "mode": "oauth",
                    vendor,
                    token,
                    lang,
                }
            ),
        }
    );

    if (response.ok) {
        const incomingToken = await response.text();
        if (incomingToken) {
            return incomingToken;
        }
        throw await response.json();
    }

    if (response.status === 400) {
        throw await response.json();
    }

    throw "Internal server error";
}

export default loginByOAuth;
