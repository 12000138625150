<template>
    <div id="app"
         v-bind:class="{ locale }">
        <router-view></router-view>
    </div>
</template>

<script>
import localForage from "localforage";
import locale2 from "locale2";
import AOS from "aos";

export default {
    components: {

    },
    metaInfo: {
        title: "marier Skincare 曼肌兒",
        titleTemplate: "%s | marier Skincare 曼肌兒"
    },
    computed: {
        userStatus () {
            return this.$store.getters["user/status"];
        },
        locale () {
            return this.$i18n.locale;
        },
    },
    async created () {
        let detectedLang = "zh-hk";
        if (await localForage.getItem("marier.user-lang")) {
            detectedLang = await localForage.getItem("marier.user-lang");
        } else {
            if (["zh-cn"].includes(locale2)) {
                detectedLang = "zh-cn";
            }
            if (["en", /*"en-US", "en-GB"*/].includes(locale2)) {
                detectedLang = "en";
            }
            if (["zh", "yue"].includes(locale2)) {
                detectedLang = "zh-hk";
            }
            await localForage.setItem("marier.user-lang", detectedLang);
        }
        this.$i18n.locale = detectedLang;

        AOS.init({ "once": true });

        this.$store.dispatch("user/loadToken");
        this.$store.dispatch("wallet/loadPaymentSession");
        this.$store.commit("cart/setCart");

        this.$store.dispatch("mall/loadMall");
        this.$store.dispatch("mall/loadShop");
        this.$store.dispatch("mall/loadSalon");
        this.$store.dispatch("mall/loadCampaigns");
    },
    mounted () {
        if (window.fbq) {
            window.fbq("init", "2721552384800689");
            window.fbq("track", "PageView");

            window.fbq("init", "372397536671511");
            window.fbq("track", "PageView");

            window.fbq("init", "3895332523861703");
            window.fbq("track", "PageView");

            window.fbq("init", "934658454012633");
            window.fbq("track", "PageView");

            window.fbq("init", "155797502850800");
            window.fbq("track", "PageView");
        }

        window.addEventListener(
            "online",
            (evt) => {
                this.$store.commit("layout/online");
            }
        );

        window.addEventListener(
            "offline",
            (evt) => {
                this.$store.commit("layout/offline");
            }
        );
    },
    watch: {
        "userStatus" (newVal) {
            if (newVal === "loggedIn") {
                this.$store.dispatch("wallet/loadWallet");
                this.$store.dispatch("mall/loadOrders");
            }
        },

        "locale" (newVal) {
            this.$store.dispatch("mall/loadMall");
            this.$store.dispatch("mall/loadShop");
            this.$store.dispatch("mall/loadSalon");
            this.$store.dispatch("mall/loadCampaigns");
        },
    }
}
</script>

<style lang="scss">
@import "~bootstrap-vue/dist/bootstrap-vue.min.css";

$animationDuration: 1000ms; // Default
@import "~vue2-animate/src/sass/vue2-animate";

@import "~quill/dist/quill.core.css";

@import "~chartist/dist/scss/chartist.scss";

$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

// From Spinkit to customize
$spinkit-spinner-margin: 40px auto !default;
$spinkit-size: 70px !default;
$spinkit-spinner-color: rgba(113, 113, 113, 1) !default;

.rounded-xl {
    border-radius: 1rem !important;
}

.sk-wandering-cubes {
    $animationDuration: 1.8s;

    margin: $spinkit-spinner-margin;
    width: $spinkit-size;
    height: $spinkit-size;
    position: relative;

    .sk-cube {
        background-color: $spinkit-spinner-color;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        animation: sk-wanderingCube $animationDuration ease-in-out #{-$animationDuration} infinite both;
    }

    .sk-cube2 {
        animation-delay: -$animationDuration / 2;
    }
}

@keyframes sk-wanderingCube {
    $cubeDistance: 50px;
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: translateX($cubeDistance) rotate(-90deg) scale(0.5);
    }
    50% {
        /* Hack to make FF rotate in the right direction */
        transform: translateX($cubeDistance) translateY($cubeDistance) rotate(-179deg);
    }
    50.1% {
        transform: translateX($cubeDistance) translateY($cubeDistance) rotate(-180deg);
    }
    75% {
        transform: translateX(0) translateY($cubeDistance) rotate(-270deg) scale(0.5);
    }
    100% {
        transform: rotate(-360deg);
    }
}

$flex-values: 1 2 3 4;
@each $value in $flex-values {
    .flex-#{$value} {
        flex: $value !important;
    }
}

$z-indexes: 1 2 3 4;
@each $index in $z-indexes {
    .z-index-#{$index} {
        z-index: $index !important;
    }
}

#nprogress {
    .bar {
        height: 4px;
    }

    .spinner {
        display: none;
    }
}

html,
body {
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        Microsoft JhengHei,
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji !important;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.view {
    margin-top: 62px;
}

.btn {
    &.btn-loading {
        position: relative;
        pointer-events: none;
        color: transparent !important;

        &:after {
            position: absolute;
            left: calc(50% - (1em / 2));
            top: calc(50% - (1em / 2));

            display: block;
            height: 1em;
            width: 1em;

            border: 2px solid;
            border-color: rgba(219, 219, 219, 1);
            border-radius: 290486px;
            border-right-color: transparent;
            border-top-color: transparent;

            content: "";

            animation: spinAround .5s infinite linear;
        }

        &.btn-outline-dark {
            &:after {
                border-color: map-get($theme-colors, "dark");
                border-right-color: transparent;
                border-top-color: transparent;
            }
        }
    }
}

.pagination-marier {
    .page-item {
        &.active {
            .page-link {
                background-color: rgba(113, 113, 113, 1) !important;
                border-color: rgba(113, 113, 113, 1) !important;
            }
        }
    }
}

.treatment-details {
    .datepicker .form-control[readonly] {
        background-color: rgba(255, 255, 255, 1) !important;
    }
}

@keyframes spinAround {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}
</style>
