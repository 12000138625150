import { apiUrl, apiVersion } from "../../config.js";

import localforage from "localforage";

export async function getShopOrders () {
    const token = await localforage.getItem("marier.user-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/shop/order`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
        }
    );

    if (response.ok) {
        const orders = await response.json();
        if (orders) {
            return orders;
        }
        throw "SHOP GET ORDERS error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getShopOrders;
