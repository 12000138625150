import NProgress from "nprogress/nprogress";

import { getMall } from "../api/mall/getMall.js";
import { getBlog } from "../api/mall/getBlog.js";
import { getNews } from "../api/mall/getNews.js";
import { getCampaigns } from "../api/mall/getCampaigns.js";

import { getShop } from "../api/shop/getShop.js";
import { getShopOrders } from "../api/shop/getShopOrders.js";
import { createShopOrder } from "../api/shop/createShopOrder.js";

import { getSalon } from "../api/salon/getSalon.js";
import { getSalonOrders } from "../api/salon/getSalonOrders.js";
import { createSalonOrder } from "../api/salon/createSalonOrder.js";

const MallStore = {
    namespaced: true,
    state: {
        mall: {
            banners: [],
        },

        testimonials: [],
        news: [],
        blog: [],

        campaigns: undefined,

        shop: {
            categories: [],
            series: [],
            products: [],
            branches: [],
            deliveryClasses: [],
        },

        salon: {
            categories: [],
            series: [],
            treatments: [],
            faqs: [],
        },

        orders: {
            shop: [],
            salon: [],
        },
    },
    getters: {
        banners ({ mall }) {
            return mall?.banners ?? [];
        },
        campaigns ({ campaigns }) {
            return campaigns ?? [];
        },
        shop ({ shop }) {
            return shop;
        },
        branches ({ shop }) {
            return shop?.branches ?? [];
        },
        shopSeries ({ shop }) {
            return shop?.series ?? [];
        },
        shopOrders ({ orders }) {
            return orders?.shop ?? [];
        },
        salon ({ salon }) {
            return salon;
        },
        salonSeries ({ salon }) {
            return salon.series ?? [];
        },
        salonOrders ({ orders }) {
            return orders.salon ?? [];
        },
        treatments ({ salon }) {
            return salon.treatments ?? [];
        },
    },
    mutations: {
        setMall (
            state,
            mall
        ) {
            state.mall = Object.freeze(mall);
        },

        setCampaigns (
            state,
            campaigns
        ) {
            state.campaigns = Object.freeze(campaigns);
        },

        setNews (
            state,
            news
        ) {
            state.news = Object.freeze(news);
        },

        setBlog (
            state,
            blog
        ) {
            state.blog = Object.freeze(blog);
        },

        setTestimonials (
            state,
            testimonials
        ) {
            state.testimonials = Object.freeze(testimonials);
        },

        setShop (
            state,
            shop
        ) {
            state.shop = Object.freeze(shop);
        },

        setSalon (
            state,
            salon
        ) {
            state.salon = Object.freeze(salon);
        },

        setOrders (
            state,
            orders
        ) {
            state.orders = orders;
        },

        createShopOrder (
            state,
            order
        ) {
            state.orders.shop.push(order);
        },

        createSalonOrder (
            state,
            order
        ) {
            state.orders.salon.push(order);
        },
    },
    actions: {
        async loadMall (context) {
            NProgress.start();
            let mall;
            try {
                mall = await getMall();
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }

            context.commit("setMall", mall);
            NProgress.done();
            return mall;
        },

        async loadShop (context) {
            NProgress.start();
            let shop;
            try {
                shop = await getShop();
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            context.commit("setShop", shop);
            NProgress.done();
            return shop;
        },

        async loadSalon (context) {
            NProgress.start();
            let salon;
            try {
                salon = await getSalon();
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            context.commit("setSalon", salon);
            NProgress.done();
            return salon;
        },

        async loadNews (
            context
        ) {
            NProgress.start();
            let news;
            try {
                news = await getNews();
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            context.commit("setNews", news);
            NProgress.done();
            return news;
        },

        async loadBlog (
            { commit, }
        ) {
            NProgress.start();
            let blog;
            try {
                blog = await getBlog();
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            commit("setBlog", blog);
            NProgress.done();
            return blog;
        },

        /*async loadTestimonials (context) {
            NProgress.start();
            let testimonials;
            try {
                testimonials = await getTestimonials();
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            context.commit("setTestimonials", testimonials);
            NProgress.done();
            return testimonials;
        },*/

        async loadOrders (
            { commit, }
        ) {
            NProgress.start();
            let orders;
            try {
                orders = await Promise.all(
                    [
                        getShopOrders(),
                        getSalonOrders(),
                    ]
                );
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            commit(
                "setOrders",
                {
                    "shop": orders[0],
                    "salon": orders[1],
                }
            );
            NProgress.done();
            return orders;
        },

        async createShopOrder (
            { commit, },
            order
        ) {
            console.log(order);

            NProgress.start();
            let result;
            try {
                result = await createShopOrder(order);
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            commit(
                "createShopOrder",
                result
            );
            NProgress.done();
            return result;
        },

        async createSalonOrder (
            { commit, },
            order
        ) {
            console.log(order);

            NProgress.start();
            let result;
            try {
                result = await createSalonOrder(order);
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            commit(
                "createSalonOrder",
                result
            );
            NProgress.done();
            return result;
        },

        async loadCampaigns (
            { commit, }
        ) {
            NProgress.start();
            let campaigns;
            try {
                campaigns = await getCampaigns();
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            commit(
                "setCampaigns",
                campaigns
            );
            NProgress.done();
            return campaigns;
        },
    }
};

export default MallStore;
