import { apiUrl, apiVersion } from "../../config.js";

import localforage from "localforage";

export async function createShopOrder (order) {
    const token = await localforage.getItem("marier.user-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/shop/order`,
        {
            "method": "POST",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            "body": JSON.stringify(order),
        }
    );

    if (response.ok) {
        const order = await response.json();
        if (order) {
            return order;
        }
        throw "SHOP CREATE ORDER error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default createShopOrder;
