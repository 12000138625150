import localforage from "localforage";
import { apiUrl, apiVersion } from "../../config.js";

export async function addAddress (address) {
    const token = await localforage.getItem("marier.user-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/wallet/address`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            body: JSON.stringify(address)
        }
    );

    if (response.ok) {
        const address = await response.json();
        if (address) {
            return address;
        }
        throw "WALLET GET error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default addAddress;
