import { apiUrl, apiVersion, } from "../../config.js";
import localForage from "localforage";

export async function getCampaigns () {
    const lang = await localForage.getItem("marier.user-lang");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/mall/campaign`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Accept-Language": lang,
            },
        }
    );

    if (response.ok) {
        let campaigns = await response.json();
        if (campaigns) {
            return campaigns;
        }
        throw "CAMPAIGN GET error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getCampaigns;
