import { apiUrl, apiVersion } from "../../config.js";
import localForage from "localforage";

export async function getSalon () {
    const lang = await localForage.getItem("marier.user-lang");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/salon`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Accept-Language": lang,
            },
        }
    );

    if (response.ok) {
        const salon = await response.json();
        if (salon) {
            return salon;
        }
        throw "SALON GET error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getSalon;
