<template>
    <section class="view treatment-faq">
        <view-title v-bind:title="$t('message.treatment-faq.title')"></view-title>
        <f-a-q-section v-for="section in faqs"
                       v-bind:key="section._id"
                       v-bind:section="section">
        </f-a-q-section>
    </section>
</template>

<script>
export default {
    name: "TreatmentFAQ",
    components: {
        FAQSection: () => import("@/components/TreatmentFAQ/FAQSection.vue"),
        ViewTitle: () => import("@/components/ViewTitle.vue"),
    },
    metaInfo: {
        title: "常見問題",
    },
    data () {
        return {

        }
    },
    computed: {
        salon () {
            return this.$store.getters["mall/salon"];
        },
        faqs () {
            return this.salon?.faqs ?? [];
        },
    },
}
</script>

<style lang="scss" scoped>
.treatment-faq {
    color: rgba(113, 113, 113, 1);
}
</style>
