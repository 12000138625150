export default {
    "zh-cn": {
        message: {
            "notifications": {
                "verificationSuccess": "您的电邮已经通过验证。您现在可以购物及预约疗程。",
                "verificationPending": "您的电邮仍在等候验证。请在收件箱中查看验证邮件。如果您没有收到电邮，请检查您的垃圾邮件文件夹或要求重新发送。",
                "verificationResend": "重新发送验证邮件",
                "offlineTitle": "閣下目前处于离线状态",
                "offline": "本网站上的信息可能已过期。阁下于离线期间提交的订单和预订将在重新连线时处理。",
                "updateAvailableTitle": "有可用更新",
                "updateAvailable": "本应用程序有新版本可用。点击更新👉",
                "updateApp": "更新",
            },

            "back-to-top-btn": {
                "title": "返回页首"
            },

            "navbar": {
                "menu": "选单",
                "basket": "购物车",
                "login-register": "登入/注册",
                "my-account": "我的帐户",
                "sign-out": "登出",
            },

            "login": {
                "title": "注册/登入",
                "login": "登入",
                "login-sns": "使用社交网络帐户登录：",
                "login-sns-or": "或",
                "login-sns-notice-1": "使用社交网络帐户登录代表阁下已同意我们的",
                "login-sns-notice-2": "私隐政策",
                "login-sns-notice-3": "使用条款",
                "login-sns-notice-and": "及",
                "register-line": "按此进入新会员注册页面：",
                "register": "注册",
                "email": "电邮地址",
                "phone": "手机号码",
                "region-code": "电话区号",
                "password": "密码",
                "forget-password": "忘记密码？",
                "forget-password-line": "请输入你注册时使用的电邮地址。我们会向该电邮地址发送一封包含重置密码指示的电子邮件。",
                "invalid-email": "请输入有效的电邮地址",
                "invalid-phone": "请输入有效的电话号码",
                "invalid-password": "请输入密码",
                "incorrect-login": "登入资料不正确。请重新输入。",
            },

            "registration": {
                "title": "新会员注册",
                "form-title": "称谓",
                "title-miss": "小姐",
                "title-ms": "女士",
                "title-mr": "先生",
                "surname": "姓氏",
                "name": "名字",
                "email": "电邮地址",
                "country-code": "电话区号",
                "phone": "电话号码",
                "password": "密码",
                "show-password": "显示密码",
                "hide-password": "隐藏密码",
                "receive-promotion": "你希望收到 marier Skincare曼肌兒的资讯及优惠吗？",
                "receive-promotion-yes": "是",
                "receive-promotion-no": "否",
                "how-marier-found": "你是如何找到marier Skincare曼肌兒？",
                "accept-tc-1": "我已阅读并同意",
                "accept-tc-2": "私穏政策",
                "accept-tc-3": "使用条款",
                "accept-tc-and": "及",
                "accept-tc-full-stop": "。",
                "register": "注册",
            },

            "forget-password": {
                "title": "重设你的密码",
                "confirm": "确认"
            },

            "account": {
                "title": "我的帐户",
                "dashboard": "帐户概览",
                "orders": "产品订单",
                "bookings": "疗程预订",

                "account": "帐户",
                "security": "安全",
                "payment-shipping": "付款和运送",
                "message-options": "来自 marier 的讯息",

                "name": "姓名",
                "phone": "电话号码",
                "email": "电邮地址",
                "language": "语言",

                "change-email-notice": "帐户验证电邮将会发送到新输入的电邮地址。您必须再次验证自己的帐户。",
                "change-phone-notice": "我們將會再次驗證您的電話號碼。",

                "password": "密码",
                "change-password": "更改密码",
                "current-password": "当前密码",
                "new-password": "新密码",

                "twoFactorAuth": "双重认证",
                "enable-two-factor-auth": "启用双重认证",
                "disable-two-factor-auth": "停用双重认证",

                "one-time-password": "单次密码",
                "otp-description": "支援单次密码应用程式，例如谷歌身份验证器，微软身份验证器及Authy。",
                "otp-verify-description": "请输入由单次密码应用程序生成的6位数安全码以进行验证：",
                "verify-token": "验证安全码",
                "scan-enter-otp": "使用您选用的单次密码应用程式扫描上述QR码或手动输入：",
                "disable-otp": "停用单次密码",

                "hw-security-key": "硬体安全钥匙",
                "hw-security-key-description": "支援符合FIDO U2F标准的硬体安全钥匙。",

                "no-data": "没有提供",
                "enable": "启用",
                "enabled": "已启用",
                "disable": "停用",
                "edit": "编辑",
                "done": "完成",
                "verify": "验证",

                "pay-order": "支付订单",
                "update-order": "更新订单",
                "cancel-order": "取消订单",
            },

            "basket": {
                "products": {
                    "title": "产品",
                    "empty": {
                        "headline": "Your basket is empty",
                        "subheadline": "Make it happy with some of our products!"
                    }
                },
                "treatments": {
                    "title": "疗程",
                    "empty": {
                        "headline": "Your basket is empty",
                        "subheadline": "Make it happy with some of our treatments!"
                    },
                    "options": "疗程选项：",
                    "date": "疗情日期：",
                    "branch": "预约分店："
                },
                "quantity": "数量",
                "unit-price": "单价：",
                "delivery": "运费：",
                "sub-total": "金额",
                "subTotal": "小计：",
                "total": "总计：",
                "delete": "删除",
                "login-verification-required": "请先登入或注册",
                "promotion": "折扣优惠：",
                "have-a-coupon-code": "有优惠卷吗？",
                "coupon-code": "优惠卷号码",
                "coupon-code-applied": "已应用优惠券",
                "redeem": "兑换",
            },

            "checkout": {
                "title": "结帐",
                "delivery": "运费",
                "promotion": "折扣优惠",
                "sub-total": "金额",
                "total": "总计",
                "my-basket": "我的购物篮",
                "my-order": "我的订单",
                "products": "种产品",
                "treatments": "疗程",
                "items": "项",
                "contact": "联络方法",
                "contact-email-notice": "订单确认通知将发送到此电邮地址。",
                "contact-phone-notice": "我们可能会透过这个电话号码与阁下联系。",
                "delivery-address": "送货地址",
                "delivery-method": "送货服务",
                "payment-method": "送貨服務",
                "delivery-standard": "标准邮递",
                "first-name": "名字",
                "last-name": "姓氏",
                "email": "电邮地址",
                "phone": "电话号码",
                "optional": "可选",
                "country": "国家 / 地区",
                "postcode": "邮政编码",
                "next": "下一步",
                "confirm": "确认訂單",
                "confirm-order": "确认訂單",
                "edit": "编辑",
                "add": "新增",
                "add-address": "新增地址",
                "address": {
                    "title": "地址",
                    "select": "选择此地址",
                    "selected": "已选择此地址",
                    "delete": "删除",
                },
                "add-another-card": "使用另一张卡",
                "accept-tc-1": "我接受",
                "accept-tc-2": "条款及细则",
                "accept-tc-3": "。",
                "thank-you": "多谢惠顾！",
                "pay-on-site-notice": "如阁下欲以现金付款或希望申请免息分期付款，请选择「现场付款」。网上付款只接受信用卡、PayPal、支付宝及微信支付。",
                "payment-methods": {
                    "pay-on-site": "现场付款",
                    "cards": "支付卡",
                    "paypal": "PayPal",
                    "alipay": "支付宝",
                    "wechat-pay": "微信支付",
                },
                "card": {
                    "select": "选择此卡",
                    "selected": "已选择此卡",
                    "delete": "删除",
                    "last-4-digit": "最后4位数字：",
                    "valid-till": "有效期至：",
                    "name": "持卡人姓名",
                },
                "add-card": {
                    "title": "添加支付卡",
                    "card-name": "持卡人姓名",
                    "card-name-hint": "卡上显示的全名",
                    "card-number": "卡号",
                    "card-expiry": "卡到期日",
                    "card-cvc": "CVC/CVV",
                    "buttons": {
                        "add": "添加",
                        "cancel": "取消",
                    },
                },
                "payment-request-api-or-cards": "或者你可以在下面选择或添加支付卡",
                "payment-in-progress": "付款进行中...",
                "cancel-payment": "取消付款",
                "payment-failed": "付款失败。",
                "retry-payment": "再试一次？",
                "confirm-email-sent": "订单确认已发送到您的电子邮件地址：",
            },

            "home": {
                "introduction": "简介",
                "tag-line-1": "停下时光 静止衰老",
                "tag-line-2": "以科研逆转肌龄",
                "intro-1": "marier Skincare曼肌兒深信干细胞对肌肤的重要性，与韩国顶尖科研团队合作，于世界各地搜罗最优质原材料，针对肌肤衰老问题。",
                "intro-2": "以高科技技术研制高效抗衰老产品，成功研发出针对干细胞生长，有高浓度亲和性的人体骨髓干细胞培养液 Stem Cell Conditioned Media (SCCM)。",
                "intro-3": "能强效抗衰老，高速修复受损细胞，为肌肤补充水分营养， 是世界No.1干细胞权威专家。",
                "featured-products": "皇牌产品",
                "featured-treatments": "皇牌疗程",
                "know-more": "查询详情",
            },

            "treatments": {
                "title": "疗程",
                "options": "疗程选项",
                "select-sku": "请选择所需疗程：",
                "select-date": "选择日期",
                "datepicker-placeholder": "点击以选择日期",
                "select-time": "选择时间",
                "select-branch": "选择分店",
                "select-branch-region": "地区",
                "duration": "所需时间",
                "openHours": "营业时间：",
                "hotline": "查询电话：",
                "price": "金额：",
                "book-now": "加入购物车",
            },

            "treatment-faq": {
                "title": "常见问题",
            },

            "products": {
                "title": "产品",
                "add-to-cart": "加入购物车",
                "added-to-cart": "已加进购物车",
                "share-to": "分享至：",
                "introduction": "产品介绍",
                "usage": "使用方法",
                "payment-methods-terms": "付款方法",
                "online-shopping-terms": "网上购物条款",
                "terms": "条款及细则",
                "delivery-methods-terms": "订购条款及送货服务",
            },

            "promotions": {
                "book-now": "立即预约",
            },

            "blog": {
                "title": "博客",
            },

            "news": {
                "title": "最新消息",
            },

            "article": {
                "read-more": "阅读全文",
            },

            "about": {
                "title": "关于marier",
                "tag-line-lead": "世界No.1干细胞权威专家marier Skincare曼肌兒宗旨：",
                "tag-line": "「探索和融合世界一切美好事物，以温和天然成分及突破性的技术，激活肌肤再生。」",
                "paragraph-1": "marier Skincare曼肌兒于2012年创办，是世界No.1干细胞权威专家，于世界各地搜罗最优质原材料，针对肌肤衰老问题，以高科技技术研制不同革新并优质的高效抗衰老产品，迎合不同亚洲人士，务求能让更多人认识marier的产品及美容服务。",
                "paragraph-2": "marier Skincare曼肌兒深信干细胞对肌肤的重要，因此与韩国顶尖科研团队合作，成功研发出针对干细胞生长，用于护肤品的高浓度亲和性的人体骨髓干细胞培养液Stem Cell Conditioned Media (SCCM)，并取得国际FDA专利，能高度及迅速修复受损细胞，为肌肤补充水分营养。",
                "pharmicell-title": "顶尖韩国科研合作团队",
                "paragraph-3": "Pharmicell 是位于韩国世界最早开发干细胞治疗剂的科研团队，是全球生物医药品的引领者。其细胞疗法实验室成立于2002年10月，主要致力于干细胞药物的商业化研究。业务领域包括干细胞治疗产品，生化学，医药品委托代工业务，干细胞银行及干细胞护肤品。",
                "paragraph-4": "干细胞护肤品是通过Pharmicell尖端技术诞生的干细胞培养液。蕴含人体干细胞培养液提取物，能有效肌肤岁月逆转。 SCCM能超越时间，重现美丽的护肤品，其干细胞培养液内含皮肤生长因子和各种活性成分，使皮肤健康亮泽，展现最美丽的肌肤。",
                "paragraph-5": "根据光照性皮肤疾病领域世界最高权威期刊，针对干细胞培养液具有皮肤保湿与皱纹改善效果的发表论文指出SCCM能有效改善皱纹，增加胶原蛋白及减少水分流失。",
                "paragraph-6": "我们累积经验，以专业角度及临床实证了解亚洲女性的肌肤需求。以革新科技鼓舞我们不断追求更高效卓越的产品。公司坚持与顶尖团队合作，研发更适合现今女性需要的产品。",
                "paragraph-7": "我们期望与客人找到爱与信任。我们专业的美容顾问以挚诚的态度、从你们的需要出发，为每一位带来贴心个人化护理疗程。",
            },

            "locations": {
                "title": "分店位置"
            },

            "privacy": {
                "title": "私隐政策"
            },

            "terms": {
                "title": "条款及细则"
            },

            "delivery-methods": {
                "title": "订购条款及送货服务",
                "delivery-cat-1": "marierskincare.com提供全球送货服务。收货国家/地区为",
                "delivery-cat-2": "类。",
                "delivery-method": "送货方式",
                "purchase-condition-larger-equal": "购物少于港币",
                "purchase-condition-larger-1": "购物港币",
                "purchase-condition-larger-2": "或以下",
                "purchase-condition-free-larger-equal-1": "购物港币",
                "purchase-condition-free-larger-equal-2": "或以上",
                "purchase-condition-free-larger": "购物高于港币$",
                "delivery-time": "运送时间（工作日）",
                "free": "免费",
            },

            "payment-methods": {
                "title": "付款方法"
            },

            "online-shopping-terms": {
                "title": "线上购物条款"
            },

            "contact-us": {
                "title": "联络我们",
                "address": "香港尖沙咀弥敦道132号Mira Place A座6楼602室",
                "form-title": "立即查询",
                "form-name-title": "称谓",
                "form-name": "姓名",
                "form-gender": "性別",
                "form-gender-male": "男",
                "form-gender-female": "女",
                "form-email": "电邮地址",
                "form-country-code": "电话区号",
                "form-phone": "联络电话",
                "form-content": "內容",
                "form-terms-checkbox": "本人同意所提供的资料可按照本人已阅读、理解及同意之法律声明及私隐政策而作出持有及使用。",
                "form-send": "发送",
                "form-complete-title": "感谢您联络我们！",
                "form-complete": "我们已经收到您的信息，我们会尽快回覆您。",
                "form-error-title": "我们遇到了一些问题",
                "form-error": "对不起，请稍后再试。",
            },

            "site-map": {
                "home": {
                    "title": "主頁",
                },
                "news": {
                    "title": "最新消息",
                },
                "about": {
                    "title": "关于 marier",
                    "brand": "品牌介绍",
                    "locations": "分店资料",
                },
                "products": {
                    "title": "产品",
                    "all": "所有类别",
                },
                "treatments": {
                    "title": "疗程",
                    "all": "疗程一览",
                },
                "promotions": {
                    "title": "最新推广",
                    "faq": "疗程FAQ",
                },
                "others": {
                    "title": "其他",
                    "contact": "联络我们",
                    "privacy": "私隐政策",
                    "terms": "条款及细则",
                    "paymentMethods": "付款方法",
                    "onlineTerms": "线上购物条款",
                    "orderTerms": "订购条款及送货服务",
                },
            },
        }
    }
}
