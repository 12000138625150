import authToken from "../api/user/authToken.js";
import register from "../api/user/register.js";
import login from "../api/user/login.js";
import loginByOAuth from "../api/user/loginByOAuth.js";
import verify2FA from "../api/user/verify2FA.js";
import logout from "../api/user/logout.js";

import localForage from "localforage";

const UserStore = {
    namespaced: true,
    state: {
        status: "initial",
        message: "",
        loggedIn: false,
        loginFailed: false,
        registerFailed: false,
        token: "",
        user: {},
    },
    getters: {
        status ({ status }) {
            return status;
        },
        info ({ user }) {
            return user;
        },
    },
    mutations: {
        resetStatus (state) {
            state.status = "loggedOut";
        },
        setLoggingIn (state) {
            state.status = "loggingIn";
        },
        setLoginSuccess (state) {
            state.status = "loggedIn";
        },
        setLoginFailed (state) {
            state.status = "loginFailed";
        },
        setVerifying2FA (state) {
            state.status = "verifying2FA";
        },
        setRegistering (state) {
            state.status = "registering";
        },
        setRegisterFailed (state) {
            state.status = "registerFailed";
        },
        setMessage (state, message) {
            state.message = message;
        },
        unsetMessage (state) {
            state.message = "";
        },
        setUser (state, user) {
            let temp = Object.assign({}, user);
            delete temp.__v;
            state.user = temp;
        }
    },
    actions: {
        async loadToken (context) {
            let user;
            try {
                user = await authToken();
            } catch (e) {
                await localForage.setItem("marier.user-token", "");
                context.commit("resetStatus");
                return;
            }

            if (user.twoFactorAuth) {
                if (!user.twoFactorAuthenticated) {
                    await localForage.setItem("marier.user-token", "");
                    context.commit("resetStatus");
                    throw "2FA not authenticated";
                }
            }

            context.commit("setLoginSuccess");
            context.commit("setMessage", "");
            context.commit("setUser", user);
            return user;
        },

        async register (
            { commit },
            payload
        ) {
            commit("setRegistering");
            commit("setMessage", "");

            let user;
            try {
                user = await register(payload);
            } catch (e) {
                console.error(e);
                commit("setRegisterFailed");
                commit("setMessage", e.reason);
                throw e;
            }

            commit("setLoggingIn");
            let token;
            try {
                token = await login(payload);
            } catch (e) {
                console.error(e);
                commit("setLoginFailed");
                commit("setMessage", e.reason);
                throw e;
            }

            user = await authToken(token);
            commit("setUser", user);
            commit("setLoginSuccess");
            await localForage.setItem("marier.user-token", token);
            return user;
        },

        async login (
            { commit },
            payload
        ) {
            commit("setLoggingIn");
            commit("setMessage", "");

            let token;
            try {
                token = await login(payload);
            } catch (e) {
                console.error(e);
                commit("setLoginFailed");
                commit("setMessage", e.reason);
                throw e;
            }

            let user;
            try {
                user = await authToken(token);
            } catch (e) {
                if (e === "2FA unauthenticated") {
                    await localForage.setItem("marier.user-token", token);
                    commit("setVerifying2FA");
                }
                throw e;
            }
            await localForage.setItem("marier.user-token", token);
            commit("setLoginSuccess");
            commit("setUser", user);
            return user;
        },

        async loginByGoogle (
            context,
            { token, lang }
        ) {
            context.commit("setLoggingIn");
            context.commit("setMessage", "");

            try {
                token = await loginByOAuth({
                    vendor: "google",
                    token: token,
                    lang: lang
                });
            } catch (e) {
                console.error(e);
                context.commit("setLoginFailed");
                context.commit("setMessage", e.reason);
                throw e;
            }

            let user;
            try {
                user = await authToken(token);
            } catch (e) {
                console.error(e);
                context.commit("setLoginFailed");
                context.commit("setMessage", e.reason);
                throw e;
            }

            context.commit("setLoginSuccess");
            context.commit("setUser", user);
            await localForage.setItem("marier.user-token", token);
            return user;
        },

        async loginByFacebook (
            context,
            { token, lang }
        ) {
            context.commit("setLoggingIn");
            context.commit("setMessage", "");

            try {
                token = await loginByOAuth({
                    vendor: "facebook",
                    token: token,
                    lang: lang
                });
            } catch (e) {
                console.error(e);
                context.commit("setLoginFailed");
                context.commit("setMessage", e.reason);
                throw e;
            }

            let user;
            try {
                user = await authToken(token);
            } catch (e) {
                console.error(e);
                context.commit("setLoginFailed");
                context.commit("setMessage", e.reason);
                throw e;
            }

            context.commit("setLoginSuccess");
            context.commit("setUser", user);
            await localForage.setItem("marier.user-token", token);
            return user;
        },

        async verify2FA (
            context,
            { type, token }
        ) {
            context.commit("setVerifying2FA");
            context.commit("setMessage", "");

            let result;
            try {
                result = await verify2FA(type, token);
            } catch (e) {
                console.error(e);
                context.commit("resetStatus");
                context.commit("setMessage", e.reason);
                throw e;
            }

            let user = await authToken(result);
            context.commit("setUser", user);
            context.commit("setLoginSuccess");
            await localForage.setItem("marier.user-token", result);

            return user;
        },

        async logout (context) {
            await logout();
            context.commit("setMessage", "");
            context.commit("resetStatus");
        },

        setMessage (context, message) {
            context.commit("setMessage", message);
        },

        unsetMessage (context) {
            context.commit("unsetMessage");
        },
    }
};

export default UserStore;
